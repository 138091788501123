<template lang="pug">
.FWatermarkRect(:style="style")
</template>

<style lang="stylus">
.FWatermarkRect
  position absolute
  height rem(120)
  width rem(740)
  border-radius 20px
  z-index var(--FWatermarkRect--zIndex)
  background var(--FWatermarkRect--color)
  opacity var(--FWatermarkRect--opacity)
  transform rotate(var(--FWatermarkRect--rotation))
  top var(--FWatermarkRect--top)
  left var(--FWatermarkRect--left)
  bottom var(--FWatermarkRect--bottom)
  right var(--FWatermarkRect--right)

  +media-down('sm')
    height rem(92)
    width rem(454)
</style>

<script setup lang="ts">
import type CSS from 'csstype';

export interface FWatermarkRectProps {
  /**
   * Color of the rectangle
   */
  color?: Color;
  /**
   * Opcacity of the rectangle
   */
  opacity?: number;
  /**
   * z-index of the rectangle
   */
  zIndex?: number | string;
  /**
   * Rotation of the rectangle in degrees
   */
  rotation?: `${number}deg`;
  /**
   * Left position of the rectangle
   */
  left?: CSS.Properties['left'];
  /**
   * Right position of the rectangle
   */
  right?: CSS.Properties['right'];
  /**
   * Top position of the rectangle
   */
  top?: CSS.Properties['top'];
  /**
   * Bottom position of the rectangle
   */
  bottom?: CSS.Properties['bottom'];
}

const props = withDefaults(defineProps<FWatermarkRectProps>(), {
  color: 'success',
  opacity: 0.2,
  zIndex: -1,
  rotation: '0deg',
  left: 'auto',
  right: 'auto',
  top: 'auto',
  bottom: 'auto',
});

const style = computed(
  (): Style => ({
    '--FWatermarkRect--color': getCssColor(props.color),
    '--FWatermarkRect--opacity': String(props.opacity),
    '--FWatermarkRect--zIndex': String(props.zIndex),
    '--FWatermarkRect--rotation': props.rotation,
    '--FWatermarkRect--left': genSize(props.left),
    '--FWatermarkRect--right': genSize(props.right),
    '--FWatermarkRect--top': genSize(props.top),
    '--FWatermarkRect--bottom': genSize(props.bottom),
  })
);
</script>
